import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { graphql, withPrefix, navigate } from "gatsby";
import Main from "../components/Main";
import StructuredData from "../components/StructuredData";

const DEFAULT_LANG = "en";

const IndexPage = ({ data }) => {
  useEffect(() => {
    const currentLanguage = localStorage.getItem("lang");
    localStorage.setItem("lang", DEFAULT_LANG);
    if (typeof window !== "undefined") {
      if (currentLanguage && currentLanguage !== DEFAULT_LANG) {
        const homeUrl = withPrefix(`/${currentLanguage || ""}/`);
        navigate(homeUrl);
      }
    }
  }, [data]);

  const texts = data.allContentfulWebsiteTexts.nodes[0];
  const portfolio = data.allContentfulPortfolio.nodes;

  const { websiteTitle, seoDescription, seoImages } = texts;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title>{websiteTitle}</title>
        <meta name="description" content={seoDescription} />
      </Helmet>

      <StructuredData seoImages={seoImages} />

      <Main texts={texts} portfolio={portfolio} />
    </>
  );
};

export const query = graphql`
  query GetWebsiteTexts {
    allContentfulWebsiteTexts(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        home
        scrollDown
        send
        skills
        skillsList
        software
        studio
        teamOne
        teamThree
        teamTwo
        touch
        works
        email
        getIn
        our
        boutique
        contact
        name
        message
        websiteTitle
        seoDescription
        companyDescription {
          raw
        }
        seoImages {
          url
        }
        formSuccessMessage {
          raw
        }
        formErrorMessage {
          raw
        }
      }
    }
    allContentfulPortfolio(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        category
        description
        title
        url
        order
        image {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            width: 1024
          )
        }
      }
    }
  }
`;

export default IndexPage;
